import React from 'react'
import Layout from '../containers/layout'
import SEO from '../components/seo'
import Contact from '../containers/contact'

type ContactPageProps = {}

const ContactPage: React.FunctionComponent<ContactPageProps> = () => {
    return (
        <Layout>
            <SEO title="Liên hệ" description="" />

            <Contact />
        </Layout>
    )
}

export default ContactPage
